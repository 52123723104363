var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-navigation-drawer',{staticClass:"color-drawer",attrs:{"app":"","permanent":!_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm,"expand-on-hover":!_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm,"mini-variant":_vm.mini},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item',{staticClass:"text-decoration-none list-item",staticStyle:{"color":"#131b1e","font-size":"18px"},attrs:{"link":"","to":{ name: 'preguntas-frecuentes' }}},[_c('v-list-item-icon',{staticStyle:{"margin-right":"5px"}},[_c('v-icon',{staticStyle:{"color":"#ffffff"}},[_vm._v("mdi-help")])],1),_c('v-list-item-title',{staticStyle:{"color":"#ffffff","font-size":"14px"}},[_vm._v("Preguntas frecuentes")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_c('v-list-item',{staticClass:"px-2",attrs:{"link":"","to":{ name: 'perfil' }}},[_c('v-list-item-avatar',{staticClass:"mr-1"},[_c('v-img',{attrs:{"src":this.img}})],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"user-name"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"user-email"},[_vm._v(" "+_vm._s(_vm.email)+" ")])],1)],1)],1)],1),_c('v-list',{attrs:{"nav":"","dense":""}},[(_vm.showNav)?[_vm._l((_vm.listNavBar),function(link,index){return [(!(_vm.role === 'Distributor' && link.nombre === 'Mis cursos'))?_c('v-list-item',{key:index,staticStyle:{"color":"#ffffff","margin-bottom":"25px !important"},attrs:{"link":"","to":{ name: link.path }}},[_c('v-list-item-icon',{staticClass:"mr-3"},[_c('v-icon',{class:{
                'active-icon': _vm.$route.name === link.path,
                'inactive-icon': _vm.$route.name !== link.path,
              }},[_vm._v(" "+_vm._s(`mdi-${link.icon}`)+" ")])],1),_c('v-list-item-title',{class:{
              'active-text': _vm.$route.name === link.path,
              'inactive-text': _vm.$route.name !== link.path,
            }},[_vm._v(" "+_vm._s(link.nombre)+" ")])],1):_vm._e()]})]:[_c('div',{staticClass:"myb-9 box animation"}),_vm._l((_vm.listNavBar.length - 1),function(qty){return _c('div',{key:qty},[_c('div',{staticClass:"my-9 box animation"})])})]],2)],1),_c('NavBar',{on:{"click":_vm.changeDrawer}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }